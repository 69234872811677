$size-xl: 20px;
$size-l: 18px;
$size-m: 16px;
$size-xm: 15px;
$size-s: 14px;
$size-xs: 12px;
$size-2xs: 10px;
$size-3xs: 8px;

//Font-family
$montserrat-500: 'Montserrat Medium';
$montserrat-400: 'Montserrat Regular';
$montserrat-600: 'montserrat semibold';

$urbanist-400: 'Urbanist';

//Colors

$blue: #49A0F8;
$purple: #5A5BF3;
$orange: #EEB14F;
$grey: #828282;
$black: #242424;
$white: #FFFFFF;
$shadow: #49A0F80D;
