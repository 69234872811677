@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

// .wrapper {
//   max-width: 320px !important;
//   margin: 0 auto !important;
//   padding: 0 16px !important;
//   width: 100%;
// }
// @media (min-width: 768px) {
//     .wrapper {
//         max-width: 768px !important;
//         margin: 0 auto !important;
//         padding: 0 24px !important;
//         width: 100%;
//     }
// }

// @media (min-width: 1024px) {
//   .wrapper {
//       max-width: 1024px !important;
//       margin: 0 auto !important;
//       padding: 0 22px !important;
//       width: 100%;
//   }
// }

// @media (min-width: 1280px) {
//   .wrapper {
//       max-width: 1280px !important;
//       margin: 0 auto !important;
//       padding: 0 85px !important;
//       width: 100%;
//   }
// }

* {
    box-sizing: border-box;
}

p {
    margin: 0;
}


.flex {
    display: flex;
}

.flex-c {
    display: flex;
    flex-direction: column;
}

.flex-w {
    display: flex;
    flex-wrap: wrap;
}

.flex-jc-sb {
    display: flex;
    justify-content: space-between;
}

.wrapper {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 20px;
}
main  {
    margin-top: 70px !important;
}

body {
    margin: 0;
    padding: 0;
    height: auto;
}

a {
    text-decoration: none;
}

main {
    min-height: 87vh;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}

li {
    font-family: 'Roboto Condensed', sans-serif;
}



.footer-text {
    font-family: 'Merriweather', serif;
    align-self: center;
    font-size: 16px;
    color: rgba(255,255,255,.4);
}
