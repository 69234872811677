@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap");
.product {
  display: flex;
  padding: 40px 0 0 0;
  margin-bottom: 20px;
  > :nth-child(1) {
    width: 300px;
    height: 300px;
    margin-right: 40px;
    border-radius: 4px;
  }
  > :nth-child(2) {
    width: 60%;
    > :nth-child(1) {
      font-family: "Roboto Condensed", sans-serif;
      text-align: center;
      margin-bottom: 8px;
      font-size: 24px;
    }
    > :nth-child(2) {
        font-family: 'Lato', sans-serif;   
        text-align: justify;
    font-size: 18px; }
  }
}


@media (max-width: 770px) {
    .product {
        display: flex;
        flex-direction: column;
        padding: 40px 0 0 0;
        margin-bottom: 20px;
        > :nth-child(1) {
          width: 280px;
          height: 280px;
          margin-right: 0;
          align-self: center;
          margin-bottom: 10px;
        }
        > :nth-child(2) {
          width: 100%;
          > :nth-child(1) {
            font-family: "Roboto Condensed", sans-serif;
            text-align: center;
            margin-bottom: 8px;
            font-size: 24px;
          }
          > :nth-child(2) {
              font-family: 'Lato', sans-serif;   
              text-align: justify;
          font-size: 18px; }
        }
      }
}
