@import "../../../assets/styles/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200&display=swap');
.home-bg {
    width: 100%;
    height: 700px;
    background-image: url('../../../assets/images/home-bg.jpeg');
    background-size: cover;
}
.home-info-wrapper {
   // background: linear-gradient(to right, rgb(255 255 255 / 52%), #bcf0ff);
    padding: 20px 0;
}
.home-info {
     text-align: center;
    >  :nth-child(1) {
        color: black;
        font-size: 24px;
        font-family: 'Assistant', sans-serif;
    }
}

.home-info-item {
    > :nth-child(1) {
        color: white;
        font-size: 20px;
        margin-bottom: 10px;
    }
    > :nth-child(2) {
        color: white;
        font-size: 14px;
    }
}

.home-navigation {
    display: flex;
    .home-nav-item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 50%;
        margin: 0;
        cursor: pointer;
    }
    .home-nav-item-content {
        text-align: center;
        h2 {
            color: #3d9991;
            font-family: $montserrat-600;
          }
          > p {
              font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
          }
          > div {
              color: #3d9991;
              font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
          }
  

    }
}

.about-us-link-img {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    background-image: url('../../../assets/images/home-about-us.jpeg');
    background-size: cover;
    border-radius: 8px;

}

.our-products-link-img {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    background-image: url('../../../assets/images/plastic-granules-19191981.jpg');
    background-size: cover;
    border: 1px solid #cfc1c1;
    border-radius: 8px;
}



@media screen and (max-width: 769px) {
    .home-navigation {
        display: flex;
        flex-direction: column;
        .home-nav-item {
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
            cursor: pointer;
            margin: 20px 0 !important;
        }
        .home-nav-item-content {
            text-align: center;
            h2 {
              color: #3d9991;
            }
            > div {
                color: #3d9991;
            }
    
        }
    }

  }
