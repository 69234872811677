@import "../../../assets/styles/variables.scss";

.contact-us-bg {
    background-image: url("../../../assets/images/contact-us.jpeg");
    height: 500px;
    width: 100%;
   background-size: cover;
   background-position: 20% 50%;
  }


.contacts-section {
  > :nth-child(1) {
      text-align: center;
      font-size: 38px;
      font-family: $montserrat-600;
      margin: 20px 0;
      margin-bottom: 40px;
  }
}

.contact-info-item {
    > :nth-child(1) {
        margin-bottom: 10px;
    }
    > :nth-child(2) {
        font-family: $montserrat-600;
        font-size: 24px;
        margin-bottom: 10px;
    }
    > :nth-child(3) {
        font-size: 16px;
        font-family: "Nunito", sans-serif;
        color: #526173;
    }
    .mail-svg {
        width: 40px ;
        height: 40px;
        background-size: cover;
        background-image: url('../../../assets/images/mail-svgrepo-com.svg');
    }
    .adress-svg {
        width: 40px ;
        height: 40px;
        background-size: cover;
        background-image: url('../../../assets/images/address-location-map-svgrepo-com.svg');
    }
    .telephone-svg {
        width: 40px ;
        height: 40px;
        background-size: cover;
        background-image: url('../../../assets/images/telephone-receiver-material-svgrepo-com.svg');
    }
    .postal-svg {
        width: 40px ;
        height: 40px;
        background-size: cover;
        background-image: url('../../../assets/images/post-office-svgrepo-com.svg');
    }
}

.contact-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.contact-info-mobile {
    display: none;
}

@media screen and (max-width: 769px) {
    .contact-info-mobile {
        display: block;
    }
    .contact-info {
        display: none;
    }
    .phone {
        margin-left: -18px;
    }
    .contact-info-item-mobile {
        display: flex;
        justify-content: space-evenly;   
        margin-bottom: 20px;
        > div {
            >:nth-child(1) {
                margin-bottom: 4px;
            }
            > :nth-child(2) {
                font-size: 20px;
                margin-bottom: 0;
            }
            > :nth-child(3) {
                font-size: 12px;
            }
        }  
 }
   }