@import "../../../assets/styles/variables.scss";

.products-bg {
  background-image: url("../../../assets/images/tobaco-plant.jpeg");
  height: 500px;
  background-position: 50% 20%;
  margin-bottom: 40px;
}

.our-products {
  color: #fff;
  padding-top: 140px;
  font-family: $montserrat-600;
  font-size: 60px;
  text-align: center;
}

.tab-header-text {
  padding: 20px;
}

.tab-menu {
    justify-content: center;
  display: flex;
  .tab-header-text {
    font-family: $montserrat-600;
  padding: 20px 15px;
  color: $grey;
  cursor: pointer;
  }
  margin-bottom: 30px;
}

.tab-header-text-active {
  font-family: $montserrat-600;
  padding: 20px 15px;
  border-bottom: 2px solid #91E7D9 ;
  color: $grey;
  cursor: pointer;
}

.products-container {
    flex-wrap: wrap;
    justify-content: center;
}



.product-item {
   text-align: center;
   font-family: $montserrat-600;
   font-size: 20px;
   color: #000000c2;
   margin: 0 20px 20px 0;
   > :nth-child(1) {
       margin-bottom: 10px;
       width: 300px;
       height: 300px;
      border-radius: 4px;
   }
}

.product-item-title {
    font-family: $montserrat-600;
    color: #635a5a;
    font-size: 24px;
}

.polyamide {
    background-image: url('../../../assets/images/poly-amide.webp');
    background-size: cover ;
}

.aluminium {
    background-image: url('../../../assets/images/aluminium.jpeg');
    background-size: cover ;
}

.cigarette-paper {
  background-image: url('../../../assets/images/cigarette-paper.jpeg');
  background-size: cover ;
}

.printed-frame {
  background-image: url('../../../assets/images/inner-frame.png');
  background-size: cover ;
}

.polypropylene {
  background-image: url('../../../assets/images/polypropylene.jpeg');
  background-size: cover ;
}

.polyethylene {
  background-image: url('../../../assets/images/polyethylene.webp');
  background-size: cover ;
}
.tobacco-product {
  background-image: url('../../../assets/images/Tobacco_Leaf_2.jpg');
  background-size: cover ;
}

.equipment {
  background-image: url('../../../assets/images/equipments.jpeg');
  background-size: cover ;
}


@media (max-width: 999px) {
  .products-container {
    :nth-child(9) {
      display: none;
    }
  }
}