@import "../../../assets/styles/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Solitreo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
.about-us-bg {
  height: 500px;
  background-image: url("../../../assets/images/home-about-us.jpeg");
  background-size: cover;
}

.oriental-tobacco {
  width: 400px;
  height: 230px;
  background-image: url("../../../assets/images/Tobacco_Leaf_2.jpg");
  background-size: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.virginna-tobacco {
  width: 400px;
  height: 230px;
  background-image: url("../../../assets/images/plastic-granules-19191981.jpg");
  background-position: right;
  background-size: cover;
  border: 1px solid #cfc1c1;
  border-radius: 10px;
  margin-right: 20px;
}

.about-us-info {
  width: 60%;
}

.about-us-content {
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  color: #526173;
}

.about-us-title {
  font-size: 24px;
  font-family: $montserrat-500;
}


.about-us {
  color: #fff;
  padding-top: 140px;
  text-align: center;
  > :nth-child(1) {
    font-family: $montserrat-600;
    font-size: 60px;
    margin:  0 0 10px 0;
  }
  >:nth-child(2) {
    font-family: 'Solitreo', cursive;
    font-size: 20px;
    margin: 0;
  }
}

.none {
  display: none;
}

@media screen and (max-width: 769px) {
 .about-us-items {
   flex-direction: column;
   > :nth-child(1) {
     width: 100%;
     margin-bottom: 10px;
   }
   > :nth-child(2) {
     width: 100%;
     > :nth-child(1) {
       text-align: center;
     }
   }
 }
 .none {
   display: block;
   height: 230px;
   background-image: url("../../../assets/images/plastic-granules-19191981.jpg");
   background-size: cover;
   border-radius: 10px;
 }
 .virginna-tobacco {
   display: none;
 }
 .about-us-title {
   text-align: center;
 }
}

