  @font-face {
    font-family: "Montserrat Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Montserrat Regular"),
      url("../fonts/Montserrat-Regular.woff") format("woff");
  }
  
  @font-face {
    font-family: "Montserrat Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Montserrat Medium"),
      url("../fonts/Montserrat-Medium.woff") format("woff");
  }
  

  @font-face {
    font-family: "montserrat semibold";
    font-style: normal;
    font-weight: normal;
    src: local("montserrat semibold"),
      url("../fonts/montserrat-semibold.woff") format("woff");
  }

  @font-face {
    font-family: 'Urbanist';
    src: local('Urbanist Regular'), local('Urbanist-Regular'),
    url('../fonts/Urbanist-Regular.woff2') format('woff2'),
    url('../fonts/Urbanist-Regular.woff') format('woff'),
    url('../fonts/Urbanist-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }