@import '../../assets/styles/variables.scss';
  html, body {
    height: 100%;
  }
  

  
  .header {
    display: flex;
    height: 70px;  
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: white;
    justify-content: space-between;
    ul {
      margin: 0;
    }
  }

  .header__body {
    display: flex;
    justify-content: space-between;
  }

  .header-logo {
    text-decoration: none;
  }

     

  .logo-text-item {
        color: $black;
        font-family: $urbanist-400;
        font-size: 24px;
        text-decoration: none;
        &:first-child {
            letter-spacing: 1px;
        }
        > span {
          font-size: 16px;
        }
    }

    .logo-text {
      align-self: center;
    }

  .header-link {
    text-transform: uppercase;
    font-size: 20px;
    text-decoration: none;
    margin-right: 30px;
    align-self: center;
    color: black;
    &:hover {
      color: #0000005c;
    }
  }

  .active {
     color: #0000005c;
  }

  .header-logo {
    align-content: center;
    justify-content: center;
  }
  

  .links {
    list-style: none;
    display: flex;
    justify-content: space-between;

  
  }





  @media (max-width: 768px) {
      .links {
        flex-direction: column;
        background-color: #0d2538;
        top: 0;
        right: 0;
        width: 60%;
       height: 100vh;
       margin: 0 !important;
      }

      li {
        font-size: 12px;
        padding: 12px 0;
        color:  white;
      }
      .header-link{
        font-size: 16px;
      }
 
  }
  

  
  